<template>
<div id ="contact">
  <div class="vx-row">

        <!-- TITLE COLOR -->
        <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base">
        <!-- ABOUT CARD -->
                <vx-card                 title="İletişim"                 title-color="primary"                 subtitle=""                 code-toggler>
                    <!-- ACTION SLOT -->
                    <template slot="actions">
                        <feather-icon icon="MoreHorizontalIcon"></feather-icon>
                    </template>
                    
                    <!-- CONTACT BIO -->
                    <div class="user-bio">
                        <p>Bizi telefonla aradığınızda, Sabah 09:00 Aksam:17:30 Saatleri arasında ofislerimizin açık olduğunu lütfen unutmayın. Saat Dilimi GMT +3:00 Ankara/TURKEY</p>
                    </div>

                    <br>
                    <!-- USER BIO -->
                    <div class="user-bio">
                        <p>Bilişim sektörüne 2011 yılında Teknolojide Güven Sloganıyla adım atan  Ceddan Bilişim Teknolojileri. son teknolojiyi, rekabetçi fiyatla ve eksiksiz destek hizmetiyle Türk BT pazarına sunmak amacıyla kurulmuştur. Hem tedarikçi firmalara hem de kurumsal ve bireysel müşterilerine yalın ve direkt süreçlerle ulaşarak, sürekli maliyet avantajı oluşturmayı hedeflemektedir. Böylelikle, kaliteden ödün vermeksizin, en yeni teknolojiyi yansıtan ürün ve çözümlerini, en uygun fiyatla pazara sunabilmektedir. En zor projelerde bile müşterilerinin yanında olan Ceddan Bilişim Teknolojileri. , sistem entegrasyon ve danışmanlık hizmetleri ile teknolojide güven sloganı çerçevesinde eksiksiz, tam çözüm sağlamaktadır.</p>
                    </div>

                    <!-- OTEHR DATA -->
                    <div class="mt-5">
                        <h6>Şirket Ünvanı:</h6>
                        <p>Ceddan Bilişim Teknolojileri Ömer Faruk DEMİRYÜREK</p>
                    </div>
                    <div class="mt-5">
                        <h6>Kurulum Yılı:</h6>
                        <p>2011</p>
                    </div>

                    <div class="mt-5">
                        <h6>Adres:</h6>
                        <p>Aydınlıkevler Mah Çağdaş Sokak No:43/A Altındağ , Ankara / Türkiye</p>
                    </div>

                    <div class="mt-5">
                        <h6>Telefon:</h6>
                        <p><a href="tel:+903125274888">+90-312-527-48-88</a></p>
                    </div>
                    <div class="mt-5">
                        <h6>Gsm:</h6>
                        <p><a href="tel:+905364991717">+90-536-499-17-17</a></p>
                    </div>

                    <div class="mt-5">
                        <h6>Email:</h6>
                        <p><a href="mailto:ceddan@ceddan.com"> ceddan@ceddan.com</a></p>
                    </div>

                    <div class="mt-5">
                        <h6>Vergi Dairesi:</h6>
                        <p>YAHYAGALİP</p>
                    </div>
                    <div class="mt-5">
                        <h6>VKN:</h6>
                        <p>49759718224</p>
                    </div>
                    <div class="mt-5">
                        <h6>Kuveytturk IBAN:</h6>
                        <i class="fa fa-try"></i><strong></strong><a href="mailto:#">TR55 0020 5000 0912 6412 4000 01</a><br/>
                    </div>
                    

                    <div class="social-links flex mt-4">
                        <a rel="nofollow" href="https://www.facebook.com/ceddanbilisim" target="_blank" ><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon" ></feather-icon></a>
                        <a rel="nofollow" href="https://www.twitter.com/ceddan" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="TwitterIcon" ></feather-icon></a>
                        <a rel="nofollow" href="https://www.instagram.com/ceddanbilisim" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="InstagramIcon" ></feather-icon></a>
                    </div>
                </vx-card>
            
        </div>

        <!-- TITLE COLOR -->
        <div class="vx-col w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 mb-base">
            <vx-card
                title="Bize Ulaşın"
                title-color ="warning"
                code-toggler>
                
                <section id="google_map_5" class="map_area-02">
          	      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3058.1763146447515!2d32.87240213995877!3d39.95981053212081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34ddcfd9c80c1%3A0x191702aef965a10!2zQ0VEREFOIELEsEzEsMWexLBNIFRFS05PTE9KxLBMRVLEsA!5e0!3m2!1str!2str!4v1610973277258!5m2!1str!2str" width="100%" height="425" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </section>
               
            </vx-card>
            <br>
            <!-- VERTICAL LAYOUT WITH ICON-->
            <vx-card title="Bize Sorun" title-color="danger" code-toggler>
				<div class="vx-row mb-6">
          <div class="vx-col w-full">
						<vs-input class="w-full" icon-pack="feather" icon="icon-home" icon-no-border label="Firma Adınız" v-model="form.company" />
					</div>
					<div class="vx-col w-full">
						<vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Adınız" v-model="form.name" />
					</div>
				</div>
				<div class="vx-row mb-6">
					<div class="vx-col w-full">
						<vs-input type="email" class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label="Email" v-model="form.mail" />
					</div>
				</div>
				<div class="vx-row mb-6">
					<div class="vx-col w-full">
						<vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border label="Mobile" v-model="form.tel" />
					</div>
				</div>
				<div class="vx-row mb-6">
					<div class="vx-col w-full">
						<vs-textarea class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label="Mesajınız" v-model="form.description" />
					</div>
				</div>
				
				<div class="vx-row">
					<div class="vx-col w-full">
						<vs-button class="mr-3 mb-2" :disabled="saveEnabled" @click="gonderMail">Gönder</vs-button>
						<vs-button color="warning" type="border" class="mb-2" @click="form.company = form.name = form.mail = form.tel = form.description = ''">Vazgeç</vs-button>
					</div>
				</div>
			</vx-card>
        </div>

        
		
        </div>
  
        </div>
</template>
<script>

//import babelPolyfill from 'babel-polyfill'
import axios from 'axios';
import qs from 'qs';
import xml2js from "xml2js";
import fs from "fs";
import notify from 'devextreme/ui/notify';
import { mapGetters } from "vuex";
export default {
  data() {
      return {
        form: {
          company: "",
          name : "",
          tel :"",
          mail: "",
          description: "",
          subject: 'İletişim',
        },
            sModuller:'',
            sOnayKodlari:'',
            sent: false,
              
        saveButtonClicked: false,
        maps:''
      }
    },
    jsonld() {
      return [
      { "@context" : "https://schema.org",
        "@type" : "Organization",
        "name": "ceddan.com",
        "url" : "https://www.ceddan.com",
        "logo": "https://www.ceddan.com/logo.png",
        "sameAs": [
            "https://www.facebook.com/ceddan",
            "https://twitter.com/ceddanbilisim",
            "https://www.youtube.com/user/ceddanbilisim",
            "https://www.instagram.com/ceddanbilisim"
        ],
        "contactPoint" : [
          { "@type" : "ContactPoint",
            "telephone" : "+90-312-527-48-88",
            "contactType" : "customer service",
            "contactOption" : "TollFree",
            "areaServed" : "TR"
          } , {
            "@type" : "ContactPoint",
            "telephone" : "+90-536-499-17-17",
            "contactType" : "Sales"
          } , 
           {
            "@type" : "ContactPoint",
            "telephone" : "+90-312-527-48-88",
            "contactType" : "technical support",
            "contactOption" : "TollFree",
            "areaServed" : "TR",
            "availableLanguage" : ["English","Turkish","Arabic"]
          } , {
            "@type" : "ContactPoint",
            "telephone" : "+90-312-527-48-88",
            "contactType" : "bill payment",
            "contactOption" : "TollFree",
            "areaServed" : ["EN","TR"]
          } ] 
      }]

    },
    methods: {
      saveProduct() {
        this.saveButtonClicked = true;
        //this.$store.dispatch("saveProduct", this.product)
      },
      sendEmail () {
      
      this.saveButtonClicked = true;
      const emailData = {
        email: this.form.mail,
        name: this.form.name,
        message: this.form.description
      }
      this.$store.dispatch('sendEmail', emailData)
      //this.form.name = ''
      //this.form.mail = ''
      //this.form.description = ''
    },
    gonderMail(){
                //console.log(event);
                //console.log(this.ContactForm);
                //console.log(this);
                //console.log(event);
                //console.log(event.target.childNodes);
                //console.log(event.srcElement.childNodes[1]._value);
                
                event.preventDefault()
                //debugger;
                var str="<?xml version='1.0' encoding='utf-8'?>"+
                      "<soap12:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap12='http://www.w3.org/2003/05/soap-envelope'>"+
                      "<soap12:Header>" +
                      "<SecuredTokenWebservice xmlns='http://tempuri.org/'>"+
                      "<sMail>"+this.getFirmaResult.sMail+"</sMail>"+
                      "<sUserName>"+this.getFirmaResult.nFirmaID+"</sUserName>"+
                      "<sPassword>"+this.getFirmaResult.sPostaKodu+"</sPassword>"+
                      "<AuthenticationToken>string</AuthenticationToken>"+
                      "<sBayii>"+this.getFirmaResult.nFirmaID+"</sBayii>"+
                      "</SecuredTokenWebservice>"+
                      "</soap12:Header>"+
                      "<soap12:Body>"+
                      "<AuthenticationUser xmlns='http://tempuri.org/' />"+
                      "</soap12:Body>"+
                      "</soap12:Envelope>";
                      
                      axios({method:'post',url:'https://www.barkodyazilimevi.com.tr/AuthToken.asmx',data:str,headers: {'Content-Type': 'text/xml','Access-Control-Allow-Headers': 'Origin'}})
                      .then((response) => {
                        //console.log(response);
                        var parseString = require('xml2js').parseString;
                        var xml = response.data
                        var AuthenticationToken =""
                        parseString(xml, function (err, result) {
                          //console.log(result["soap:Envelope"]["soap:Body"][0].AuthenticationUserResponse[0].AuthenticationUserResult[0]);
                          AuthenticationToken = result["soap:Envelope"]["soap:Body"][0].AuthenticationUserResponse[0].AuthenticationUserResult[0];
                        });
                      var sFirma = "<p><strong><u>" + this.form.description + ";</u></strong><br><br><br>" + "Firma:<strong>" + this.form.company  + "</strong><br>"+"Adım:<strong>" + this.form.name  + "</strong><br>"+"Mail Adresim:<strong>" + this.form.mail  + "</strong><br>"+"Telefon Numaram:<strong><a href='tel:" + this.form.tel + "'>" + this.form.tel + "</strong></p>";
                      //console.log(sFirma);
                      var ip=window.location.host; 
                      axios('https://api.ipify.org?format=json')
                        .then(response => {
                            ip=response.data.ip
                            console.log(ip);
                        
                      var str2="<?xml version='1.0' encoding='utf-8'?>"+
                      "<soap12:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap12='http://www.w3.org/2003/05/soap-envelope'>"+
                      "<soap12:Header>" +
                      "<SecuredTokenWebservice xmlns='http://tempuri.org/'>"+
                      "<sMail>"+this.getFirmaResult.sMail+"</sMail>"+
                      "<sUserName>"+this.getFirmaResult.nFirmaID+"</sUserName>"+
                      "<sPassword>"+this.getFirmaResult.sPostaKodu+"</sPassword>"+
                      "<AuthenticationToken>"+AuthenticationToken+"</AuthenticationToken>"+
                      "<sBayii>"+this.getFirmaResult.nFirmaID+"</sBayii>"+
                      "</SecuredTokenWebservice>"+
                      "</soap12:Header>"+
                      "<soap12:Body>"+
                      "<mail_gonder xmlns='http://tempuri.org/'>"+
                      "<sSubject>"+this.form.subject+"</sSubject>"+
                      "<nFirmaID>-3</nFirmaID>"+
                      //"<nFirmaID>"+this.getFirmaResult.nFirmaID+"</nFirmaID>"+
                      "<sKodu></sKodu>"+
                      "<sAciklama></sAciklama>"+
                      "<sAdres1></sAdres1>"+
                      "<sAdres2></sAdres2>"+
                      "<sSemt></sSemt>"+
                      "<sIl></sIl>"+
                      "<telefon></telefon>"+
                      "<sEMail>"+this.form.mail+"</sEMail>"+
                      "<sVergiDairesi></sVergiDairesi>"+
                      "<sVergiNo></sVergiNo>"+
                      "<nAlisverisID>0</nAlisverisID>"+
                      "<lFisNo>0</lFisNo>"+
                      "<sFirma><![CDATA["+  sFirma + "]]></sFirma>"+
                      "<sModuller><![CDATA[<strong>"+this.sModuller+"</strong>]]></sModuller>"+
                      "<sOnayKodlari><![CDATA["+this.sOnayKodlari+"]]></sOnayKodlari>"+
                      "<sOdemeSekli>"+this.form.subject+"</sOdemeSekli>"+
                      "<sIp>"+ip+"</sIp>"+
                      "</mail_gonder>"+
                      "</soap12:Body>"+
                      "</soap12:Envelope>";
                      
                      //console.log(str2);
                      axios({method:'post',url:'https://www.barkodyazilimevi.com.tr/AuthToken.asmx',data:str2,headers: {'Content-Type': 'text/xml; charset=utf-8','Access-Control-Allow-Headers': 'Origin'}})
                      .then((response) => {
                          if (response.status==200){
                            console.log(response.statusText);
                            notify(this.$t('thanks'), "info", 5000);
                            this.sent=true;
                            this.form.name="";
                            this.form.mail="";
                            this.form.company="";
                            this.form.tel="";
                            this.form.description="";
                          }
                          else{
                                console.log(response); 
                          };
                        
                        event.preventDefault();
                        
                      })
                })
                }
                      );

               
            },
    },

    computed : {
        isLoading(){
            return {
            display: "none"
          }
        },
        saveEnabled(){
          return false
        },
        ...mapGetters("auth", ["getFirmaResult"])
    }

}
</script>